import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Layout from "../layouts/Layout";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import search_icon from '../assets/images/icons/Search.svg';
import '../assets/css/order.css'
import LoadingBar from "react-top-loading-bar";
import { useDispatch } from "react-redux";
import { setUserData } from "../store/UserData";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import AllProductsModal from "../components/AllProductsModal";
import Toaster from "../components/common/Toaster";

const Orders = () => {

  useEffect(() => {
    document.title = "KANFAST POS | Orders";
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState();
  const [search, setSearch] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const dispatch = useDispatch();
  const datePickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showProducts, setShowProducts] = useState(false);
  const [orderNote, setOrderNote] = useState();
  const [isDisabled, setIsDisabled] = useState();
  const [exchangedTotal, setExchangedTotal] = useState();
  const [oldTotal, setOldTotal] = useState(0.00);
  const [newTotal, setNewTotal] = useState(0.00);
  const [needToPay, setNeedToPay] = useState(0.00);
  const [exchangeDetailsId, setExchangeDetailsId] = useState();
  const [exchangedProducts, setExchangedProducts] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  function handleSelect(ranges) {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
    setStartDate(ranges?.selection?.startDate)
    setEndDate(ranges?.selection?.endDate)
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }

  useEffect(() => {
    ref.current.continuousStart();
    var url = `pos/order-list?filterDate=${startDate.toISOString().split('T')[0]}|${endDate.toISOString().split('T')[0]}`;
    if (search) {
      url += "&search=" + search;
    }
    // if(isLoggedIn){
    axios.get(url)
      .then(resp => {
        setIsLoading(false);
        setOrders(null);
        console.log(resp.data);
        ref.current.complete();
        if (resp.data.success == true) {
          setOrders(resp.data.data);
        }
        else if (resp.data.success == false) {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: resp.data.message,
            showConfirmButton: true,
            // timer: 1000
          })
          if (resp.data.message == "Unauthorized") {
            localStorage.removeItem("posUser");
            dispatch(setUserData(null))
            navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        // localStorage.removeItem("posUser");
        // dispatch(setUserData(null))
        // navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
      });
    // }
  }, [search, startDate]);
  useEffect(() => {
    setExchangedTotal(exchangedProducts.reduce((total, product) => {
      return total + parseFloat(product.product_price) * product.qty;
    }, 0));
    // setOrderDetails(orderDetails.filter(product => product.id !== item?.id));
  }, [exchangedProducts])
// console.log(exchangedTotal);
  useEffect(()=>{
   setOldTotal(Number(orderDetails?.order_details?.reduce((total, product) => {
      return total + parseFloat(product.product_price) * product.product_quantity;
    }, 0)))
  },[orderDetails?.order_details, oldTotal])

  useEffect(()=>{
    if(Number(exchangedTotal)>0){
    setNewTotal(Number(orderDetails?.order_details.reduce((total, product) => {
                          return total + parseFloat(product.product_price) * product.product_quantity;
                        }, 0)) + Number(exchangedTotal))
                      }
  },[orderDetails?.order_details, oldTotal, exchangedTotal])

  useEffect(()=>{
    setNeedToPay(Number(orderDetails?.total_amount) - ((isNaN(oldTotal) ? 0.00 : oldTotal) + Number(exchangedTotal)))
  },[oldTotal,exchangedTotal])

  const removeOrderDetail = (detailId) => {
    const updatedOrder = {
      ...orderDetails,
      order_details: orderDetails.order_details.filter(detail => detail.id !== detailId)
    };
    setOrderDetails(updatedOrder);
  };

  const addQuantityById = (productId, quantityToAdd) => {
    // Use map to create a new array with the updated quantity
    const updatedProducts = exchangedProducts.map(product => {
        // If the product id matches, update its quantity by adding the quantityToAdd
        if (product.id === productId) {
            // Return a new object with the updated quantity
            return {
                ...product,
                qty: product.qty + quantityToAdd
            };
        }
        // For other products, return them unchanged
        return product;
    });

    // Update the state with the new array of products
    setExchangedProducts(updatedProducts);
};
  // console.log(orderDetails);
  const updateOrder = () => {

    axios
      .post("pos/order/exchange-product", {
        products: exchangedProducts,
        order_number: orderDetails?.order_number,
        order_from: "pos",
        order_note: orderNote,
      })
      .then((resp) => {
        console.log(resp);
        if(resp.data.success){
          Toaster('Product Exchanged Successfully', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(exchangedProducts);
  return (
    <div>
      <LoadingBar
        color='#003342'
        ref={ref}
      />
      <Layout>
        <Row>
          <Col md={8} className="ps-0">
            <div className="products_tab">
              <Row className="d-flex  justify-content-center mt-3">
                <Col md={6}>
                  <div className="input-group ms-2 mt-2">
                    <input type="search" className="form-control search_input" placeholder="Enter Order Number To Search.." aria-label="Recipient's username" aria-describedby="basic-addon2"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)} />
                    <span className="input-group-text search_input_icon" id="basic-addon2"><img src={search_icon} alt="search icon" /></span>
                  </div>
                </Col>
                <Col md={4}>

                  <div className="somediv" ref={datePickerRef}>

                    <div className="date-time-box mt-2" onClick={() => setShowPicker(!showPicker)}>
                      <i className="fa fa-calendar pe-2"></i>
                      <span>{startDate.toISOString().split('T')[0]} - {endDate.toISOString().split('T')[0]}</span>
                      {showPicker ? <i className="fa fa-caret-up ps-2"></i>
                        :
                        <i className="fa fa-caret-down ps-2"></i>
                      }

                    </div>
                    {showPicker && <div className="position-absolute" style={{ zIndex: '99' }}>
                      <DateRangePicker
                        ranges={[selectionRange]}
                        rangeColors={['#003342', '#003342', '#003342']}
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        showPreview={false}
                      />
                    </div>}

                  </div>

                </Col>
                <Col md={2} className="ps-0 d-flex justify-content-center align-items-center">
                  <div className="countOrder mt-2 align-bottom">
                    <p className="pe-3">{orders ? orders.length : '0'} Orders</p>
                  </div>
                </Col>
              </Row>
              <Row>
                {orders && (
                  orders.map((order, index) => {
                    return (
                      <div key={index} className={orderDetails && orderDetails.id === order.id ? 'col-11 order_card active' : 'col-11 order_card'} onClick={() => {
                        setOrderDetails(order);
                      }}>
                        <h4 className="mt-1 mb-0" style={{ color: '#003342' }}>Order #{order.order_number}</h4>
                        <div style={{ float: 'right' }}>
                          <h4 className="mb-0">৳{order.total_amount}&nbsp;</h4>
                          <p>{order.order_details.length} Item(s)&nbsp;</p>
                        </div>
                        <p className="mb-0"><svg viewBox="64 64 896 896" focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
                        </svg><b>{order.customer_name}</b></p>
                        <p className="mb-0"><svg viewBox="64 64 896 896" focusable="false" data-icon="field-time" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M945 412H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h256c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM811 548H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h122c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM477.3 322.5H434c-6.2 0-11.2 5-11.2 11.2v248c0 3.6 1.7 6.9 4.6 9l148.9 108.6c5 3.6 12 2.6 15.6-2.4l25.7-35.1v-.1c3.6-5 2.5-12-2.5-15.6l-126.7-91.6V333.7c.1-6.2-5-11.2-11.1-11.2z"></path><path d="M804.8 673.9H747c-5.6 0-10.9 2.9-13.9 7.7a321 321 0 01-44.5 55.7 317.17 317.17 0 01-101.3 68.3c-39.3 16.6-81 25-124 25-43.1 0-84.8-8.4-124-25-37.9-16-72-39-101.3-68.3s-52.3-63.4-68.3-101.3c-16.6-39.2-25-80.9-25-124 0-43.1 8.4-84.7 25-124 16-37.9 39-72 68.3-101.3 29.3-29.3 63.4-52.3 101.3-68.3 39.2-16.6 81-25 124-25 43.1 0 84.8 8.4 124 25 37.9 16 72 39 101.3 68.3a321 321 0 0144.5 55.7c3 4.8 8.3 7.7 13.9 7.7h57.8c6.9 0 11.3-7.2 8.2-13.3-65.2-129.7-197.4-214-345-215.7-216.1-2.7-395.6 174.2-396 390.1C71.6 727.5 246.9 903 463.2 903c149.5 0 283.9-84.6 349.8-215.8a9.18 9.18 0 00-8.2-13.3z"></path>
                        </svg>&nbsp;{order.date}</p>
                      </div>
                    );
                  })
                )}

                {!isLoading && !orders?.length > 0 &&
                  <div className="col-11 ps-4 mt-4">
                    <h3 className="text-warning text-center">
                      No Order To Show!
                    </h3>
                  </div>
                }

              </Row>
            </div>
          </Col>
          <Col md={4} className="ps-0 ">
            <div className="cart_section">
              {orderDetails && (
                <>
                  <div className="customer_section row">
                    <div className="col-8">
                      <div className="order_number">
                        Order #{orderDetails.order_number}
                      </div>
                    </div>
                    <div className="col-4 mt-4">
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
                      </svg>&nbsp;<b className="underline">{orderDetails?.customer_name}</b>
                    </div>
                  </div>
                  <div className="cart_items">
                    {orderDetails.order_details.map((item, key) => {
                      return (
                        <div key={key} className="cart_item_card me-2 mt-1" style={{ cursor: 'pointer' }}>
                          <div className="row w-100 d-flex justify-content-center align-items-center">
                            <div className="col-2 d-flex justify-content-center" data-title="Product">
                              <img className="img-fluid cart_product_image" src={item.product_image} width='100' height='100' alt="alt" />
                            </div>
                            <div className="col-5 py-2" data-title="Name">
                              <h5 className="m-0" title={item.product_name}>
                                {item.product_name}
                              </h5>
                              <h6 className=""> ৳{item.product_price} x {item.product_quantity} </h6>{" "}
                            </div>
                            <div data-title="Price" className="col-2 orderPrice text-center">
                              <h4 className="text-body"> ৳{(item.line_total)} </h4>
                            </div>
                            <div data-title="Price" className="col-3 orderPrice text-center">
                              <button className="btn btn-secondary" title="Exchange!" type="button" onClick={() => { setExchangeDetailsId(item?.id); setShowProducts(true) }}><i className="fa fa-exchange" aria-hidden="true"></i></button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {exchangedProducts.map((item, key) => {
                      return (
                        <div key={key} className="cart_item_card me-2 mt-1 pb-1" style={{ cursor: 'pointer' }}>
                          <div className="row me-2 justify-content-between position-relative" style={{ width: "100%" }}>

                            <div className="col-2 center_col d-flex justify-content-center" data-title="Product">
                              <img className=" cart_product_image mt-0 p-1" src={item.image} width='100' height='100' alt="Foster Farms Takeout Crispy classNameic" />
                            </div>
                            <div className="col-3 center_col" data-title="Name">
                              <h5 className="m-0 cart-productName" title={item.product_name} >
                                {item.product_name}
                                <h6 className="text-body cart-productQ"> ৳{item.product_price} x {item.qty} </h6>{" "}
                              </h5>
                            </div>

                            <div className="col-3 mx-2 d-flex justify-content-center items-center">
                              <div className="quantity_button_group">
                                <button
                                  className="quantity_button"
                                  disabled={item.qty <= 1}
                                onClick={() => addQuantityById(item?.id, -1)}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="2px" viewBox="0 0 12 1.5">
                                    <rect data-name="Rectangle 970" width="12px" height="2px" fill="white"></rect>
                                  </svg>
                                </button>
                                <span className="form-control quantity_value text-center">{item.qty}</span>
                                <button
                                  className="quantity_button"
                                  onClick={() => addQuantityById(item?.id, 1)}
                                  disabled={item.stock ? item.stock <= item.qty : item.stock <= item.qty} >
                                  <svg data-name="plus (2)" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                    viewBox="0 0 12 12">
                                    <g data-name="Group 5367">
                                      <path data-name="Path 17138"
                                        d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                                        fill="white"></path>
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div data-title="Price" className="col-2 ps-0 center_col">

                              <h6 className="text-body text-center mb-0 mx-auto"> ৳{(item.product_price * item.qty)} </h6>{" "}
                            </div>

                            <div data-title="Remove" className=" ps-0 center_col">

                              <button data-title="Remove" className="btn cross-btn border border-0" onClick={() => {
                                Toaster('Product removed from cart', 'info');
                                setExchangedProducts(exchangedProducts.filter(product => product.id !== item?.id));
                              }
                              }>
                                <i style={{ color: 'red' }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 512 512">
                                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                                  </svg>
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                  <div className="cart_totals" style={{ width: '30.2%' }}>
                    <hr style={{ marginTop: '0', marginLeft: '-14px' }} />
                    <Row>
                      <Col md={8}>
                        <h5>Status:</h5>
                      </Col>
                      <Col md={4}>
                        <h5 className="text-end pe-3">
                          {orderDetails.status === 'confirm' ?
                            (<span className="text-success">Confirm</span>) :
                            (orderDetails.status !== 'pending' && orderDetails.status !== 'cancel' ?
                              <span className="text-info">{orderDetails.status}</span> :
                              (orderDetails.status === 'cancel' ? <span className="text-danger">Cancel</span> : orderDetails.status === 'pending' && <span className="text-warning">Pending</span>))}
                        </h5>
                      </Col>
                      <Col md={8}>
                        <h5>Paid Total:</h5>
                      </Col>
                      <Col md={4}>
                        <h5 className="text-end pe-3">৳{orderDetails.total_amount}</h5>
                      </Col>
                      <Col md={8}>
                        <h5>New Total:</h5>
                      </Col>
                      <Col md={4}>
                        <h5 className="text-end pe-3">৳{isNaN(newTotal) ? 0.00 : newTotal}</h5>
                      </Col>
                      <Col md={8}>
                        <h5>Need To Pay:</h5>
                      </Col>
                      <Col md={4}>
                        <h5 className="text-end pe-3">৳{(Number(needToPay) < 0) ? Math.abs(Number(needToPay)) : 0.00}</h5>
                      </Col>
                      <Col md={12} className="mb-2">
                        <div className="ms-1">
                          <label htmlFor="order_note" className="pt-2 mb-1">Order Notes </label>
                          <textarea id="order_note" className="form-control" placeholder="Order Notes" name="order_note" value={orderNote}
                            onChange={(e) => { setOrderNote(e.target.value); }} />
                        </div>
                      </Col>
                      <Col md={12} className="p-3">
                        <button className="btn cart_order_btn bg-nameColor mb-2" disabled={isDisabled} onClick={() => {
                          updateOrder();
                        }}>
                          <p className="text-start ps-2 pt-2">Update Order
                            <br />
                            <i>{0} Items</i>
                          </p>
                          <p>{(Number(needToPay) < 0) ? Math.abs(Number(needToPay)) : 0.00}৳&nbsp;
                            <span role="img" aria-label="double-right" className="anticon anticon-double-right">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="double-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z"></path></svg>
                            </span></p>

                          <div className="proceedHover"></div>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </>

              )}

            </div>
          </Col>
        </Row>
      </Layout>
      <AllProductsModal show={showProducts}
        onHide={() => setShowProducts(false)}
        exchangedProducts={exchangedProducts}
        exchangeDetailsId={exchangeDetailsId}
        removeOrderDetail={removeOrderDetail}
        setExchangedProducts={setExchangedProducts} />
    </div>
  );
};



export default Orders;
