import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'react-select-search/style.css'
import axios from 'axios';
import { CartProvider } from 'react-use-cart';
import { Provider } from 'react-redux';
import store from './store/Store.js';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './assets/font-awesome-4.7.0/css/font-awesome.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <CartProvider>
        <App />
    </CartProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
