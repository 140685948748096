import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import SelectSearch from "react-select-search";
import Swal from "sweetalert2";

const AddCustomerAddressModal = (props) => {
    const [defaultValue, setDefault] = useState(false);
    const navigate = useNavigate();
    const [saveAddressDisable, setSaveAddressDisable] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [area, setArea] = useState("");
    const [zip, setZip] = useState("");
    const [address, setAddress] = useState("");
    // const [city, setCity] = useState("");
    const [errorList, setError] = useState();
    const addAddressSubmit = (event) => {
        setSaveAddressDisable(true);
        event.preventDefault();
        var obj = {
            name: username,
            email: email,
            phone: contact,
            address: address,
            city: props.city,
            zip: zip,
            area: area,
            area_id: null,
            shipping_id: 0,
            is_default: defaultValue
        };
        if (props.city === 'inside_dhaka') {
            obj.shipping_id = 1;
        }
        else if (props.city === 'outside_dhaka') {
            obj.shipping_id = 2;
        }
        // console.log(props.customer_id);
        // console.log(obj);
        axios
            .post("pos/add-customer-address/" + props.selectedCustomer.id, obj)
            .then(function (resp) {
                setSaveAddressDisable(false);
                var data = resp.data;
                console.log(data);
                if (data.success === false) {
                    setError(data.message);
                }
                else {
                    Swal.fire({
                        customClass: {
                            icon: 'mt-4'
                        },
                        position: 'center',
                        icon: 'success',
                        title: data.message,
                        showConfirmButton: false,
                    });
                    props.setCustomerAddressId(data.customer_address.id);
                    props.customerAddress(props.selectedCustomer.id);
                    props.setAddAddressModal(false);
                }
                if (resp?.data?.message === "Unauthorized") {
                    localStorage.removeItem("posUser");
                    navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
                }
            })
            .catch((err) => {
                setSaveAddressDisable(false);
                props.setAddressHtml();
                props.setAddAddressModal(false);
                console.log(err);
                setError(err.response.data.errors)
            });

        // console.log(errorList);
        event.preventDefault();
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Add Customer Address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ps-4 pe-4">
                        <form onSubmit={(e) => { addAddressSubmit(e); }}>
                            <div className="form-group mb-2">
                                <label htmlFor="name" className="required">
                                    Receiver Name:
                                </label>{" "}
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter Receiver Name"
                                    className="form-control square"
                                />
                                <span className='text-danger'>{errorList && errorList.name && errorList.name[0]}</span>
                            </div>{" "}
                            <div className="form-group mb-2">
                                <label htmlFor="email">Email:</label>{" "}
                                <input id="email" type="email" name="email" value={email}
                                    onChange={(e) => setEmail(e.target.value)} placeholder="your-email@domain.com" className="form-control square" />
                                {errorList && (<span className='text-danger'>{errorList.email && errorList.email}</span>)}
                            </div>{" "}
                            <div className="form-group mb-2">
                                <label htmlFor="phone" className="required">
                                    Phone:
                                </label>{" "}
                                <input id="phone" type="text" name="phone" value={contact} onChange={(e) => setContact(e.target.value)} className="form-control square" />
                                {errorList && (<span className='text-danger'>{errorList.phone}</span>)}
                            </div>{" "}
                            <div className="form-group mb-2">
                                <label htmlFor="city" className="required">
                                    City:
                                </label>{" "}
                                <select style={{ height: '47px' }} className="form-control address-control-item address-control-item-required"
                                    id="city"
                                    name="city"
                                    value={props.city}
                                    required
                                    onChange={(e) => { props.setCity(e.target.value); }}
                                >
                                    <option>Select City</option>
                                    <option value={'inside_dhaka'}>Inside Dhaka</option>
                                    <option value={'outside_dhaka'}>Outside Dhaka</option>
                                </select>
                                {errorList && (<span className='text-danger'>{errorList.city}</span>)}
                            </div>{" "}
                            <div className="form-group mb-2">
                                <label htmlFor="area" className="required">
                                    Area:
                                </label>{" "}
                                {props.city !== 'outside_dhaka' && (
                                    // <div className="form-group">
                                    // <SelectSearch options={DhakaShippingZoneData} value={area} search={true} name="area" placeholder="Select Area" onChange={(selectedValue,selectedOption) => {setArea(selectedOption.name);console.log(selectedOption);}} />
                                    // </div>
                                    // shippingZones && (
                                    <div className="form-group mb-2" id="area">
                                        <select style={{ height: '47px' }} className="form-control address-control-item address-control-item-required"
                                            name="area"
                                            required={area === "" ? true : false}
                                            value={area}
                                            onChange={(e) => setArea(e.target.value)}
                                        >
                                            <option value={null}>Select Area</option>
                                            {props.shippingZones &&
                                                (props.shippingZones.map((srvzn, index) => {
                                                    return <option key={index} value={srvzn.name}>{srvzn.name}</option>;
                                                }))}
                                        </select>
                                        {errorList && (
                                            <span className="text-danger">
                                                {errorList['shipping_details.area'] && errorList['shipping_details.area'][0]}
                                            </span>
                                        )}
                                    </div>
                                    // )
                                )}
                                {props.city === 'outside_dhaka' && (
                                    props.shippingZones && (
                                        <div className="form-group" id="area">
                                            {/* <SelectSearch options={props.shippingZones} value={area} search={true} name="area" placeholder="Select Area" onChange={(selectedValue) => { setArea(selectedValue); }} /> */}
                                        </div>
                                    )
                                )}
                                {errorList && (<span className='text-danger'>{errorList.area}</span>)}
                            </div>{" "}
                            <div className="form-group mb-2">
                                <label htmlFor="zip" className="">
                                    Zip:
                                </label>{" "}
                                <input id="zip" type="text" name="zip" value={zip} onChange={(e) => setZip(e.target.value)} placeholder="Enter your zip" className="form-control square" />
                            </div>{" "}
                            <div className="form-group mb-2">
                                <label htmlFor="address" className="required">
                                    Address:
                                </label>{" "}
                                <input
                                    id="address"
                                    type="text"
                                    name="address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required="required"
                                    placeholder="Enter your address"
                                    className="form-control square"
                                />
                                {errorList && (<span className='text-danger'>{errorList.address}</span>)}
                            </div>{" "}
                            <div className="form-group mb-2">
                                <div className="custome-checkbox">
                                  <input type="checkbox" name="is_default" value={1} id="is_default" onClick={(e) => setDefault(!defaultValue)} className="form-check-input" />{" "}
                                  <label htmlFor="is_default" className="form-check-label">
                                    <span>Use this address as default.</span>
                                  </label>
                                </div>
                              </div>{" "}
                            <div className="col-md-12">
                                <button type="submit" className="btn customer_add_btn" disabled={saveAddressDisable}>
                                    Save address
                                </button>&nbsp;
                                <button type="reset" className="btn btn-light" onClick={() => props.setAddAddressModal(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddCustomerAddressModal;
