import React, { useEffect, useRef, useState } from "react";
import Layout from "../layouts/Layout";
import { Col, Modal, Row } from "react-bootstrap";
import Swal from 'sweetalert2';
import '../assets/css/Home.css'
// import { Link } from "react-router-dom";
import search_icon from '../assets/images/icons/Search.svg';
import bar_icon from '../assets/images/icons/bar.png';
import plus_icon from '../assets/images/icons/plus.png';
// import ghee_image from '../assets/images/ghee.jpg';
import cart_image from '../assets/images/icons/icon-cart.svg';
import tag_icon from '../assets/images/icons/tag_icon.png';
import discount_icon from '../assets/images/icons/discount_icon.png';
import { toast } from 'react-toastify';
import { useCart } from "react-use-cart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import SelectSearch from "react-select-search";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../store/UserData";
import LoadingBar from "react-top-loading-bar";
import ProductAttributes from "../components/ProductAttributes";
import AddCustomerModal from "../components/AddCustomerModal";
import AddCustomerAddressModal from "../components/AddCustomerAddressModal";
import Categories from "../components/Categories";
import PosInvoice from "../components/PosInvoice";
import html2pdf from "html2pdf.js";
import GeneratePDF from "../components/common/GeneratePDF";
import Toaster from "../components/common/Toaster"
// import Select from "react-select/dist/declarations/src/Select";
import Select from 'react-select';


const Home = () => {
  const { isEmpty, inCart } = useCart();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [selectedCustomerAddresses, setSelectedCustomerAddress] = useState();
  const navigate = useNavigate();
  const { addItem, totalUniqueItems, cartTotal, items, updateItemQuantity, removeItem, updateItem, emptyCart } = useCart();
  const [searchProduct, setSearchProduct] = useState();
  const [itemExpend, setItemExpend] = useState();
  const [products, setProducts] = useState();
  const [subtotal, setSubtotal] = useState(0.00);
  const [discount, setDiscount] = useState(0.00);
  const [couponAmount, setCouponAmount] = useState(0.00);
  const [tax, setTax] = useState(0.00);
  const [grandTotal, setGrandTotal] = useState(subtotal - discount - couponAmount + tax);
  const [searchCustomer, setSearchCustomer] = useState();
  const [customers, setCustomers] = useState();
  const [selectedCustomer, setCustomer] = useState();
  const [selectedCustomerAddressesId, setCustomerAddressId] = useState();
  const [cateID, setCateID] = useState();
  const [query, setQuery] = useState("?platform=pos");
  const [addAddressModal, setAddAddressModal] = useState();
  const [addCustomerModal, setAddCustomerModal] = useState();
  const [city, setCity] = useState("");
  // const [shipingCost, setShipingCost] = useState("0.00");
  const [error, setError] = useState();
  let [orderNote, setOrderNote] = useState("");
  let [salesPerson, setSalesPerson] = useState("");
  let [paymentMethod, setPaymentMethod] = useState("");
  const [isDisabled, setDisable] = useState(false);
  const { UserData } = useSelector((state) => state.UserData);
  const [couponId, setCouponId] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const [isEventTriggured, setIsEventTriggured] = useState(false);

  const search = useLocation().search;
  const queryParam = new URLSearchParams(search);




  useEffect(() => {
    document.title = "KANFAST POS";
  }, []);

  useEffect(() => {
    // console.log(UserData);
    ref.current.continuousStart();
    if (UserData || queryParam.get('token') && !localStorage.getItem("user")) {
      // console.log('pos verify');
      var token = queryParam.get('token') !== null ? queryParam.get('token') : (UserData ? UserData.token : '');
      axios.get("pos/verify-token/" + token)
        .then(resp => {
          // console.log(resp.data);
          ref.current.complete();
          localStorage.removeItem("posUser");
          dispatch(setUserData(null))
          if (resp.data.success) {
            emptyCart();
            ref.current.complete();
            localStorage.setItem("posUser", JSON.stringify(resp.data.data));
            dispatch(setUserData(resp.data.data));
          }
          else if (resp.data.success == false) {
            ref.current.complete();
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: resp.data.message,
              showConfirmButton: false,
              timer: 1000
            })
            localStorage.removeItem("posUser");
            dispatch(setUserData(null))
            navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
          }

        }).catch(err => {
          ref.current.complete();
          console.log(err);
          navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
        });

    }
    else {
      localStorage.removeItem("posUser");
      dispatch(setUserData(null));
      navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
    }
  }, [queryParam.get('token')]);
  // console.log(UserData);


  useEffect(() => {
    setQuery("");
    if (searchProduct && cateID) {
      setQuery("?platform=pos&product_name=" + searchProduct + "&category_id=" + cateID);
    }
    else if (searchProduct) {
      setQuery("?platform=pos&product_name=" + searchProduct);
    }
    else if (cateID) {
      setQuery("?platform=pos&category_id=" + cateID);
    }
    else {
      setQuery("?platform=pos");
    }
    // console.log(query);
  }, [cateID, searchProduct]);


  useEffect(() => {
    // setProducts(null);
    // console.log(searchProduct);
    if (UserData?.token) {
      if (searchProduct && cateID && query) {
        axios.get("pos/products" + query)
          .then(resp => {
            if (resp.data.success === false && queryParam.get('token') === null) {
              if (resp.data.message == "Unauthorized") {
                localStorage.removeItem("posUser");
                navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
              }
              setProducts(null);
            }
            else {
              setProducts(resp.data.data);
              // console.log(resp.data.data);
            }
          });
      }
      else if (searchProduct && query) {

        axios.get("pos/products" + query)
          .then(resp => {
            setProducts(null);
            if (resp.data.success === false && queryParam.get('token') === null) {
              if (resp.data.message == "Unauthorized") {
                localStorage.removeItem("posUser");
                navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
              }
              setProducts(null);
            }
            else {
              setProducts(resp.data.data);
            }
          });
      }
      else if (cateID && query) {
        axios.get("pos/products" + query)
          .then(resp => {
            console.log(resp.data);
            if (resp.data.success === false && queryParam.get('token') === null) {
              if (resp.data.message == "Unauthorized") {
                localStorage.removeItem("posUser");
                navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
              }
              setProducts(null);
            }
            else {
              setProducts(resp.data.data);
            }
          });
      }
      else {
        axios.get("pos/products?platform=pos")
          .then(resp => {
            console.log(resp.data);
            if (resp.data.success === false && queryParam.get('token') === null) {
              if (resp.data.message == "Unauthorized") {
                localStorage.removeItem("posUser");
                navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
              }
              setProducts(null);
            }
            else {
              setProducts(resp.data.data);
            }
          });
      }
    }
  }, [query, UserData]);

  useEffect(() => {
    setSubtotal(cartTotal);
    setGrandTotal(subtotal - Number(discount) - Number(couponAmount) + Number(tax));
  }, [cartTotal, discount, couponAmount, tax, subtotal, grandTotal]);



  const [customerOptions, setCustomerOption] = useState([]);

  useEffect(() => {
    setCustomers(null);
    if (searchCustomer) {
      if (!isNaN(searchCustomer) && searchCustomer.length > 9) {
        axios.get("pos/customer-list?customerSearchInput=" + searchCustomer)
          .then(resp => {
            console.log(resp.data.data);
            if (resp.data.data.length > 0) {
              setCustomers(resp.data.data);
              setCustomerOption(resp.data.data.map((customer) => ({
                value: customer.customer_contact,
                label: customer.customer_name,
                customer: customer,
              })))
            }
            if (resp?.data?.message === "Unauthorized") {
              localStorage.removeItem("posUser");
              navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
            }
          });
      }
      else if (isNaN(searchCustomer) && searchCustomer.length > 2) {
        axios.get("pos/customer-list?customerSearchInput=" + searchCustomer)
          .then(resp => {
            console.log(resp.data.data);
            if (resp.data.data.length > 0) {
              setCustomers(resp.data.data);
              setCustomerOption(resp.data.data.map((customer) => ({
                value: customer.customer_contact,
                label: customer.customer_name,
                customer: customer,
              })))
            }
            if (resp?.data?.message === "Unauthorized") {
              localStorage.removeItem("posUser");
              navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
            }
          });
      }
    }
  }, [searchCustomer]);

  const [addressHtml, setAddressHtml] = useState(null);

  const customerAddress = (customer_id) => {
    setSelectedCustomerAddress(null);
    axios
      .get("pos/get-customer-addresses/" + customer_id)
      .then(function (resp) {
        setSelectedCustomerAddress(resp.data.data);
        if (resp?.data?.message === "Unauthorized") {
          localStorage.removeItem("posUser");
          navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log('customerAddress load function hit');
  }
  // console.log(selectedCustomerAddresses);
  useEffect(() => {
    let radioHtml = '';
    if (selectedCustomerAddresses) {
      try {
        selectedCustomerAddresses.forEach(function (item, key) {
          radioHtml += `<label>
          <input type="radio" name="address_id" value="` + item.id + `" required ` + (selectedCustomerAddressesId === item.id ? 'checked' : '') + `>
          <span class="swal2-label">
          `+ item.address + ` ` + item.area + `<br>
          <i style="color: rgb(9, 179, 212)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
      </svg></i> `+ item.name + `<br>
      <i style="color: rgb(9, 179, 212)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
      </svg></i> `+ item.phone + `<br>
      `+ item.shipping_state.name + ` |
      Zip: `+ item.zip + `<br>
          </span>
          </label>
        <input type="hidden" id="address_name`+ item.id + `" value="` + item.name + `" required>
        <input type="hidden" id="address`+ item.id + `" value="` + item.address + `" required>
        <input type="hidden" id="address_phone`+ item.id + `" value="` + item.phone + `" required>
        <input type="hidden" id="address_email`+ item.id + `" value="` + item.email + `" required>
        <input type="hidden" id="shipping_id`+ item.id + `" value="` + item.shipping_id + `" required>
        <input type="hidden" id="zip`+ item.id + `" value="` + item.zip + `" required>
        <input type="hidden" id="area`+ item.id + `" value="` + item.area + `" required>`;
        });

      } catch (error) {
        Swal.fire({
          customClass: {
            icon: 'mt-4'
          },
          position: 'center',
          icon: 'success',
          title: 'Ops! Something Went Wrong',
          showConfirmButton: true,
        });
        console.error(error);
      }
    }
    else {
      radioHtml = `<h5 className="text-warning">No Address Found</h5>`;
    }
    setAddressHtml(radioHtml)
    // console.log('changes in the radio html' + radioHtml);
  }, [selectedCustomerAddresses]);
  // console.log(city);
  // console.log(selectedCustomerAddresses);
  const [couponLoading, setCouponLoading] = useState(false);
  const applyCoupon = (e) => {
    e.preventDefault()
    if (isEmpty) {
      Swal.fire('Add Products First');
    }
    else {
      setCouponLoading(true);
      axios.post("pos/coupon-apply",
        { coupon: e.target.elements.coupon_code.value, sub_total: subtotal })
        .then(function (resp) {
          setCouponLoading(false);
          if (resp.data.success) {
            Toaster('Coupon Applied Successfully', 'success');
            setCouponAmount(resp.data.data.coupon_discount);
            setCouponId(resp.data.data.coupon_id);
          } else {
            Toaster(resp.data.message, 'error');
          }
        })
        .catch((err) => {
          setCouponLoading(false);
          console.log(err);
        });
    }
  }

  const checkOutSubmit = () => {
    ref.current.continuousStart();
    if (isEmpty) {
      Swal.fire('Add Products First');
      setDisable(false);
      ref.current.complete();
    }
    else if (!paymentMethod) {
      Swal.fire('Select Payment Method');
      setDisable(false);
      ref.current.complete();
    }
    else {
      setDisable(true);
      var customer_details = {
        customer_name: selectedCustomer?.customer_name,
        customer_email: selectedCustomer?.customer_email,
        customer_phone: selectedCustomer?.customer_contact,
        customer_address: selectedCustomer?.customer_address,
      }
      var products = [];
      items.forEach(function (item) {
        // console.log("listcart theke id"+item.id);
        products.push({
          id: item.id,
          product_id: item.product_id,
          product_name: item.name,
          product_price: item.price,
          qty: item.quantity,
          image: item.image,
          variation_id: item.variation_id && item.variation_id,
          variation_value: item.variation_value && item.variation_value,
          variation_stock: item.variation_stock && item.variation_stock,
          variation_final_price: item.variation_final_price && item.variation_final_price
        });
      });

      var order = {
        admin_id: UserData.id,
        customer_id: selectedCustomer?.id ?? null,
        customer_details: customer_details,
        products: products,
        order_note: orderNote,
        sales_person: salesPerson,
        payment_method: paymentMethod,
        vat: tax,
        coupon_id: couponId,
        order_from: 'pos'
      };
      // console.log(order);
      axios
        .post("pos/order", order)
        .then(function (resp) {
          setDisable(false);
          var data = resp.data;
          if (resp.data.success) {
            setOrderDetails(resp?.data?.data);
            ref.current.complete();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 1200
            });
            // generatePDF();
            setTimeout(() => {

              GeneratePDF('invoice-POS');

            }, 1200);

            setTimeout(() => {

              emptyCart();

            }, 1500);
          }
          else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Please check your inputs where the error messages are showing',
              showConfirmButton: true
            });
            ref.current.complete();
            setError(resp.data.message);
            setDisable(false);
          }
          if (resp?.data?.message === "Unauthorized") {
            localStorage.removeItem("posUser");
            navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
          }

        })
        .catch((err) => {
          console.log(err);
          setDisable(false);
          ref.current.complete();
          if (err) {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Something went wrong please check all your data',
              showConfirmButton: true
            });
          }
        });


    }
  };
  // console.log(products);
  const [productAttributesOpen, setProductAttributesOpen] = useState();
  const [modalProductAttributes, setModalProductAttributes] = useState();

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsEventTriggured(true)
      console.log("Enter key");
    }

  };

  useEffect(() => {
    if (isEventTriggured && products?.length === 1) {
      const attribute = products[0]?.attributes.find(attribute => attribute.sku === searchProduct);
      const cartId = `${products[0]?.id}_${attribute?.attribute_id}`;
      if (attribute.stock > 0) {
        addItem({
          id: cartId,
          product_id: products[0].id,
          name: products[0].name + " - " + attribute.attribute_value,
          price: Number(products[0].final_product_price).toFixed(2),
          quantity: 1,
          image: products[0].image.small,
          stock: products[0].stock,
          variation_id: attribute?.attribute_id,
          variation_final_price: attribute.final_product_price,
          variation_stock: attribute.stock,
          variation_value: attribute.attribute_value,
        });
        Toaster('Product added to cart', 'success');
        setSearchProduct('')
        setIsEventTriggured(false)
      } else {
        Toaster('Product is out of stock', 'info');
      }
    }
  }, [isEventTriggured, products])
  const searchInputRef = useRef(null);

  // Function to focus the search input field if the pressed key is alphanumeric
  const focusSearchInputIfAlphanumeric = (event) => {
    const key = event.key;
    const isAlphanumeric = /^[a-zA-Z0-9]$/i.test(key);
    const isInputOrTextArea = ['input', 'textarea'].includes(event.target.tagName.toLowerCase());
    if (isAlphanumeric && !isInputOrTextArea) {
      searchInputRef.current.focus();
    }
  };

  // Listen to keydown events at the document level
  useEffect(() => {
    document.addEventListener('keydown', focusSearchInputIfAlphanumeric);
    return () => {
      document.removeEventListener('keydown', focusSearchInputIfAlphanumeric);
    };
  }, []);
  return (
    <div className="bg-light">
      <LoadingBar
        color='#003342'
        ref={ref}
      />
      <Layout>
        <ProductAttributes show={productAttributesOpen} modalProductAttributes={modalProductAttributes} onHide={() => setProductAttributesOpen(false)} />
        <Row>
          <Col lg={8} xxl={8} className="ps-0 pe-0">
            <div className="products_tab">

              {/* Categories component */}
              <Categories setCateID={setCateID} cateID={cateID} />
              {/* Categories component end */}

              <h3 className="px-2">Products</h3>
              <Row className="justify-content-center">
                <Col md={8}>
                  <div className="input-group ms-2 position-relative">
                    <input type="search" className="form-control search_input rounded-1" placeholder="Search Product.." aria-label="Recipient's username" aria-describedby="basic-addon2"
                      value={searchProduct}
                      onChange={(e) => setSearchProduct(e.target.value)}
                      onKeyDown={handleSearchKeyDown}
                      ref={searchInputRef}
                    />
                    <span className="input-group-text search_input_icon" id="basic-addon2"><img src={search_icon} alt="search icon" /></span>
                  </div>
                </Col>
                <Col xs={3} md={1}>
                  <button className="btn basic_btn" onClick={() => {
                    Swal.fire({
                      title: 'Enter/Scan Bar Code',
                      input: 'text',
                      inputPlaceholder: 'Enter/Scan Bar Code',
                      confirmButtonColor: '#09b3d4',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setSearchProduct(result.value);
                      }
                    })
                  }}><img src={bar_icon} height={30} alt="bar icon" /></button>
                </Col>
                {/* <Col xs={3} md={1} className="ps-0">

                  <button className="btn basic_btn"><img src={plus_icon} height={30} alt="bar icon" /></button>
                </Col> */}
                <Col xs={6} md={3}>
                  <p className="text-end pe-3">{products ? products.length : '0'} Products</p>
                </Col>
              </Row>



              <div className="Default_productList">

                  <div className="product_list pb-5 row gap-2 flex-wrap">
                    {products &&
                      products.map((product, index) => {
                        return (
                          <div className="productmainDIv col-2" key={index}>
                            <div className="pos_product_card" onClick={() => {
                              // errorNotify();
                              console.log(product.attributes?.length);
                              if (product.attributes?.length < 2) {
                                if (product.stock > 0 && product.attribute_id === undefined) {
                                  addItem({
                                    id: product.id,
                                    product_id: product.id,
                                    name: product.name,
                                    price: Number(product.final_product_price).toFixed(2),
                                    quantity: 1,
                                    image: product.image.small,
                                    stock: product.stock
                                  });

                                  Toaster('Product added to cart', 'success');
                                } else if (product.stock > 0) {
                                  addItem({
                                    id: product.id + '_' + product.attribute_id,
                                    product_id: product.id,
                                    name: product.name,
                                    price: Number(product.final_product_price).toFixed(2),
                                    quantity: 1,
                                    image: product.image.small,
                                    stock: product.stock,
                                    variation_id: product.attribute_id,
                                    variation_final_price: product.final_product_price,
                                    variation_stock: product.stock,
                                    variation_value: product.name,
                                  });

                                  Toaster('Product added to cart', 'success');
                                } else {
                                  Toaster('Product is out of stock', 'error');
                                }
                              }
                              else {
                                setProductAttributesOpen(true);
                                setModalProductAttributes(product);
                              }

                            }}>
                              {product && isNaN(Number(product.discount)) && (<span className="discount_badge">{product.discount}</span>)}
                              <div className="position-relative">
                                <div xs={6} className="cardImage" >
                                  {product && product.image && product.image && (

                                    <img className="pos_product_img" src={product.image.small} alt={product.name} />
                                  )}
                                </div>
                                <div xs={6} className="pos_product_details text-center">
                                  <h2 className="mb-0" title={product.name}>{product.name}</h2>
                                </div>

                                <div className="p_price">
                                  <p className="mb-0 d-flex align-items-center justify-content-center">{product.formatted_final_product_price} {isNaN(Number(product.discount)) && (<span className="old-price">{product.formatted_regular_price}</span>)}</p>
                                </div>
                                <div className="stock">
                                  {product.stock > 0 ?
                                    <span className="in_stock">In Stock</span> :
                                    <span className="out_stock">Out Of Stock</span>}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
              </div>
            </div>
          </Col>
          <Col lg={4} xxl={4} className="ps-0 cart_sectionbg">
            <div className="cart_section mt-2">
              <div className="customer_section row px-2">
                {selectedCustomer ? (
                  <>
                    <p className="p-0 m-0 sarchCusText"> Selected customer</p><br />
                    <div className="col-12 px-0 d-flex justify-content-between">
                      <div className="selected_customer d-flex justify-content-between">
                        {selectedCustomer?.customer_name} ({selectedCustomer.customer_contact})
                      </div>

                      <div className="deleteCratCus">
                        <button data-title="Remove" className="btn ps-1 pe-1 pt-0 pb-0 addcartdelete" onClick={() => {
                          setCustomer(null);
                          setCustomerAddressId(null);
                          setDisable(false);
                        }}>
                          <i style={{ margin: 'auto' }}>
                            <i style={{ margin: 'auto' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" className="fw-bold addcartdeleteBtn" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z">
                                </path>
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z">
                                </path>
                              </svg>
                            </i>
                          </i>
                        </button>
                      </div>
                    </div>

                  </>
                ) : (
                  <>
                    <p className="p-0 m-0 sarchCusText">Search & add customer</p><br />
                    <div className="col-10">
                      {/* <input type="search" className="form-control sarchCus" placeholder="Search name, phone or email" value={searchCustomer} onChange={(e) => setSearchCustomer(e.target.value)} /> */}

                      <Select className="searchDropdown"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          colors: {
                            ...theme.colors,
                            primary25: '#069fce',
                            primary: '#003342',
                          },
                        })}
                        value={searchCustomer} onInputChange={
                          (v) => {
                            setSearchCustomer(v)
                          }} onChange={(customer) => {
                            setCustomer(customer.customer);
                          }} options={customerOptions} />
                      {/* {searchCustomer && customers && (
                        <div className="customer_class_search_result">
                          {customers && customers.map((customer, key) => {
                            return (
                              <p key={key} onClick={() => {
                                setCustomer(customer);
                                console.log(customer);
                              }} className="mb-1">{customer?.customer_name} ({customer?.customer_contact})</p>
                            )
                          })}
                        </div>
                      )} */}

                    </div>
                    <div className="col-2 ps-0">
                      <button className="col-7 col-xl-5 btn customer_add_btnH overflow-hidden bg-nameColor" title="Add Customer" onClick={() => {
                        setError(null);
                        setCustomer(null);
                        setAddCustomerModal(!addCustomerModal);
                      }}>
                        <i>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="White" className="bi bi-person-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                            <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                          </svg>
                        </i>
                      </button>

                    </div>
                    <AddCustomerModal
                      show={addCustomerModal}
                      onHide={() => setAddCustomerModal(false)}
                      setCustomer={setCustomer}
                      setCustomerAddressId={setCustomerAddressId}
                      setAddCustomerModal={setAddCustomerModal}
                      city={city}
                      setCity={setCity}
                    />
                  </>
                )}
              </div>
              <h4 className="ps-2 pt-2 pr-2 pb-0 cartText"><img src={cart_image} alt="cart icon" className="img-fluid" width={28} /> Cart Items</h4>
              <div className="cart_items">
                {items.map((item, key) => {
                  return (
                    <div key={key} className="cart_item_card me-2 mt-1 pb-1" style={{ cursor: 'pointer' }}>
                      <div className="row me-2 justify-content-between position-relative" style={{ width: "100%" }} onClick={() => {
                        if (itemExpend === item.id) {
                          setItemExpend(null);
                        }
                        else {
                          setItemExpend(item.id);
                        }
                      }}>


                        <div className="col-2 center_col d-flex justify-content-center" data-title="Product">
                          <img className=" cart_product_image mt-0 p-1" src={item.image} width='100' height='100' alt="Foster Farms Takeout Crispy classNameic" />
                        </div>
                        <div className="col-3 center_col" data-title="Name">
                          <h5 className="m-0 cart-productName" title={item.name} >
                            {item.name}
                            <h6 className="text-body cart-productQ"> ৳{item.price} x {item.quantity} </h6>{" "}
                          </h5>
                          {/* <p className="mb-0"> <small>(Weight: 4KG)</small></p> */}
                        </div>

                        {/* <div className="col-1 ">
                          {itemExpend === item.id ? (

                            <i style={{ color: '#09b3d4' }} className="ps-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                              </svg></i>
                          ) : (
                            <i style={{ color: '#09b3d4' }} className="ps-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                              </svg></i>

                          )}
                        </div> */}

                        <div className="col-3 mx-2 d-flex justify-content-center items-center">
                          {/* <label className="quantity_price_label" htmlFor="quantity_value">Quantity</label> */}
                          <div className="quantity_button_group">
                            <button
                              className="quantity_button"
                              disabled={item.quantity <= 1}
                              onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="2px" viewBox="0 0 12 1.5">
                                <rect data-name="Rectangle 970" width="12px" height="2px" fill="white"></rect>
                              </svg>
                            </button>
                            {/* <input title="Quantity" id="quantity_value" type='number' className="form-control quantity_value text-center" value={item.quantity} onChange={(e) => updateItemQuantity(item.id, e.target.value)} /> */}
                            <span className="form-control quantity_value text-center">{item.quantity}</span>
                            <button
                              className="quantity_button" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                              disabled={item.stock ? item.stock <= item.quantity : item.stock <= item.quantity} >
                              <svg data-name="plus (2)" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                viewBox="0 0 12 12">
                                <g data-name="Group 5367">
                                  <path data-name="Path 17138"
                                    d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                                    fill="white"></path>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>


                        <div data-title="Price" className="col-2 ps-0 center_col">

                          <h6 className="text-body text-center mb-0 mx-auto"> ৳{(item.price * item.quantity)} </h6>{" "}
                          {/* <small>
                                <del>$90.00</del>
                              </small> */}
                        </div>

                        <div data-title="Remove" className=" ps-0 center_col">

                          <button data-title="Remove" className="btn cross-btn border border-0" onClick={() => {
                            Toaster('Product removed from cart', 'info');
                            removeItem(item.id);
                          }
                          }>
                            <i style={{ color: 'red' }}>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg> */}
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 512 512">
                                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                              </svg>
                            </i>
                          </button>
                        </div>
                      </div>
                      {/* <div className="row" style={{ marginTop: '-1.25rem' }}> */}
                      {/* {itemExpend === item.id && ( */}
                      <>
                        {/* <div className="col-3 mx-2">
                              <label className="quantity_price_label" htmlFor="quantity_value">Quantity</label>
                              <div className="quantity_button_group">
                                <button
                                  className="quantity_button"
                                  disabled={item.quantity <= 1}
                                  onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="2px" viewBox="0 0 12 1.5">
                                    <rect data-name="Rectangle 970" width="12px" height="2px" fill="currentColor"></rect>
                                  </svg>
                                </button>
                              
                                <span className="form-control quantity_value text-center">{item.quantity}</span>
                                <button
                                  className="quantity_button" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                                  disabled={item.stock ? item.stock <= item.quantity : item.stock <= item.quantity} >
                                  <svg data-name="plus (2)" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                    viewBox="0 0 12 12">
                                    <g data-name="Group 5367">
                                      <path data-name="Path 17138"
                                        d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                                        fill="currentColor"></path>
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            </div> */}
                        {/* <div className="col-5">
                              <label className="quantity_price_label" htmlFor="unit_price">Unit Price</label>
                              <input title="Unit Price" id="unit_price" type='number' className="form-control text-center" value={item.price} onChange={(e) => updateItem(item.id, {
                                price: e.target.value,
                              })} min={100} />
                            </div> */}
                      </>
                      {/* )} */}
                      {/* </div> */}
                    </div>
                  );
                })}

              </div>
              <div className="cart_totals">
                <hr style={{ marginTop: '0', marginLeft: '-14px' }} />
                <Row>
                  <Col lg={12} xl={12}>
                    <form method="post" onSubmit={(e) => applyCoupon(e)} className="d-flex mb-2">
                      <input type="search" className="form-control me-3 coupun-input btn-outline-none" name="coupon_code" placeholder="Enter coupon code" title="Coupon code" required />
                      <button type="submit" className={`btn btn-md w-25 py-2 position-relative bg-nameColor CouponBtn ${couponLoading ? "button--loading" : ""}`}>
                        <span className="button__text"><i className="fa fa-tags me-2" ariaHidden="true"></i>Apply</span>
                      </button>
                    </form>
                  </Col>
                  {/* <Col lg={6} xl={4}>
                    <button className="btn discount_card" onClick={() => {
                      Swal.fire({
                        title: 'Apply Discount',
                        html: `<b className="mb-2">Select discount type</b> <br>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input discount_type" type="radio" name="type" id="fixed" value="fixed">
                                    <label className="form-check-label" for="fixed">Fixed</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input discount_type" type="radio" name="type" id="percentage" value="percentage">
                                    <label className="form-check-label" for="percentage">Percentage</label>
                                  </div>
                                `,
                        input: 'number',
                        inputPlaceholder: 'Enter Discount Amount',
                        inputAttributes: {
                          min: '0',
                          step: 0.1,
                        },
                        confirmButtonColor: '#09b3d4',
                        confirmButtonText: 'Apply',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          axios.post("pos/discount/" + Swal.getHtmlContainer().querySelector("input[name='type']:checked").value,
                            { discount_amount: result.value, subtotal: subtotal })
                            .then(function (resp) {
                              if (resp.data.success) {
                                // console.log(resp.data.data.discounted_amount);
                                Toaster('Discount Applied Successfully', 'success');
                                setDiscount(resp.data.data.discounted_amount);
                              }
                              else {
                                Toaster(resp.data.message, 'error');
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      })
                    }}>
                      <img src={discount_icon} alt="discount" width='30' /><br />
                      Discount
                    </button>
                  </Col> */}
                  {/* <Col lg={6} xl={4}>
                    <button className="btn discount_card" onClick={() => {
                      Swal.fire({
                        title: 'Enter Order Comment',
                        input: 'text',
                        inputPlaceholder: 'Order Comment',
                        confirmButtonColor: '#09b3d4',
                        confirmButtonText: 'Add',
                        showCancelButton: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // axios.post("pos/coupon-apply/",
                          //   { coupon: result.value, sub_total: subtotal })
                          //   .then(function (resp) {
                          //     if (resp.data.success) {
                          //       // console.log(resp.data);
                          //       successNotify('Coupon Applied Successfully');
                          //       setCouponAmount(resp.data.data.coupon_discount);
                          //       setCouponId(resp.data.data.coupon_id);
                          //     } else {
                          //       errorNotify(resp.data.message);
                          //     }
                          //   })
                          //   .catch((err) => {
                          //     console.log(err);
                          //   });
                          Toaster('This feature is upcoming', 'info');
                        }
                      })
                    }}>
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="pause" width="1.5em" height="1.9em" fill="currentColor" aria-hidden="true"><path d="M304 176h80v672h-80zm408 0h-64c-4.4 0-8 3.6-8 8v656c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V184c0-4.4-3.6-8-8-8z"></path></svg>
                      <br />
                      Hold Order
                    </button>
                  </Col> */}
                  <Col md={8}>
                    <h6>Subtotal:</h6>
                  </Col>
                  <Col md={4}>
                    <p className="text-end">{Number(subtotal.toFixed(2))}৳</p>
                  </Col>
                  <Col md={8}>
                    <h6>Tax:</h6>
                  </Col>
                  <Col md={4}>
                    <p className="text-end">{tax > 0 ? Number(tax.toFixed(2)) : '0.00'}৳</p>
                  </Col>
                  <Col md={8}>
                    <h6>Discount:</h6>
                  </Col>
                  <Col md={4}>
                    <p className="text-end">
                      {discount > 0 && (

                        <button data-title="Remove" className="btn pt-0" onClick={() => {
                          Toaster('Discount removed', 'info')
                          setDiscount(0.00);
                        }
                        }>
                          <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg></i>
                        </button>
                      )}
                      -{discount > 0 ? Number(discount) : '0.00'}৳</p>
                  </Col>
                  <Col md={8}>
                    <h6>Applied Coupon(s):</h6>
                  </Col>
                  <Col md={4}>
                    <p className="text-end">
                      {couponAmount > 0 && (

                        <button data-title="Remove" className="btn pt-0" onClick={() => {
                          Toaster('Coupon removed', 'info');
                          setCouponAmount(0.00);
                        }
                        }>
                          <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg></i>
                        </button>
                      )}
                      -{couponAmount && couponAmount > 0 ? Number(couponAmount) : '0.00'}৳</p>
                  </Col>
                  {/* <Col md={8}>
                    <h6>Shipping Charge:</h6>
                  </Col>
                  <Col md={4}>
                    <p className="text-end">{Number(shipingCost)}৳</p>
                  </Col> */}
                  <Col md={6}>
                    <select className="form-control ms-1" name="payment_method" title="Payment Method" required
                      value={paymentMethod}
                      onChange={(e) => { setPaymentMethod(e.target.value); }}>
                      <option value={null} selected >Payment Method
                      </option>
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                      <option value="mobile-banking">Mobile Banking</option>
                    </select>
                  </Col>
                  <Col md={6}>
                    <div className="ms-1">
                      <input type="text" id="sales_person" className="form-control" placeholder="Salesperson Reference" name="sales_person" value={salesPerson}
                        onChange={(e) => { setSalesPerson(e.target.value); }} />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="ms-1">
                      <label htmlFor="order_note" className="pt-2 mb-1">Order Notes </label>
                      <textarea id="order_note" className="form-control" placeholder="Order Notes" name="order_note" value={orderNote}
                        onChange={(e) => { setOrderNote(e.target.value); }} />
                    </div>
                  </Col>
                </Row><br />
                <button className="btn cart_order_btn bg-nameColor mb-2" disabled={isDisabled} onClick={() => {
                  checkOutSubmit();
                }}>
                  <p className="text-start ps-2 pt-2">Proceed To Order
                    <br />
                    <i>{totalUniqueItems} Items</i>
                  </p>
                  <p>{Number(grandTotal).toFixed(2)}৳&nbsp;
                    <span role="img" aria-label="double-right" className="anticon anticon-double-right">
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="double-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z"></path></svg>
                    </span></p>

                    <div className="proceedHover"></div>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>

      <AddCustomerAddressModal show={addAddressModal}
        onHide={() => setAddAddressModal(false)}
        customerAddress={customerAddress}
        setCustomerAddressId={setCustomerAddressId}
        selectedCustomer={selectedCustomer}
        city={city}
        setCity={setCity}
        setAddressHtml={setAddressHtml}
        setAddAddressModal={setAddAddressModal}
      />

      <PosInvoice products={items} grandTotal={grandTotal} tax={tax} storeDetails={UserData} orderDetails={orderDetails} />
    </div>
  );
};

export default React.memo(Home);
