import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Layout from "../layouts/Layout";
// import PosIcon from "../assets/images/point-of-sale.png";
import PosIcon from "../assets/images/PosLogin.png"
import '../assets/css/login.css';
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUserData } from "../store/UserData";
import LoadingBar from "react-top-loading-bar";
import Logoimg from '../assets/images/KANFAST_Black.svg'

const Login = () => {

  useEffect(() => {
    document.title = "KANFAST POS | Login";
    window.scrollTo(0, 0);
    ref.current.continuousStart();
    setTimeout(() => {
      ref.current.complete();
    }, 100);

  }, []);
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const queryParam = new URLSearchParams(search);
  let [username, setUserName] = useState("");
  let [password, setPassword] = useState("");
  const [errorList, setError] = useState("");
  useEffect(() => {
    // console.log("Full render")
    if (queryParam.get('q') != null) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: queryParam.get('q'),
        // showConfirmButton: false,
        // timer: 2000
      })
    }
  }, []);

  const loginSubmit = (e) => {
    var obj = { admin_input: username, password: password };
    ref.current.continuousStart();
    // pos/login 
    axios
      .post("pos/login", obj)
      .then(function (resp) {
        var data = resp.data;
        // console.log(data);
        if (data.success === false) {
          ref.current.complete();
          setError(resp.data.message);
          // if(errorList.admin_input[0]===undefined){
          //   Swal.fire({
          //     position: 'center',
          //     icon: 'error',
          //     title: resp.data.message,
          //     showConfirmButton: false,
          //     timer: 2000
          //   })
          // }
          localStorage.removeItem("posUser");
          dispatch(setUserData(null));
        }
        // console.log(localStorage.getItem("user"));
        if (data.success) {
          localStorage.setItem("posUser", JSON.stringify(data.data));
          dispatch(setUserData(data.data));
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: data.message,
            showConfirmButton: false,
            timer: 1000
          });
          navigate("/", { replace: true });
          // window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    e.preventDefault();
  };
  // console.log(errorList);
  return (
    <div>
      <LoadingBar
        color='#003342'
        ref={ref}
      />
      <div className="wrapper">
        <div className="loginImgMain position-relative">
          <div className="logo">
            <img src={PosIcon} alt="" className="loginlogo" />
          </div>
          <div className="text-center text-light name position-absolute pointOfSale">
            Point of Sale
          </div>

          <div className="text-center text-light name position-absolute pointOfSaleLogo">
            <img src={Logoimg} alt="logo" />
          </div>
          {/* <div className="text-center text-light name position-absolute pointOfSaleCreate">
            <a>Create Account</a>
          </div> */}
        </div>


        <div className="px-2 inputDivMain ">
          <form className="" onSubmit={(e) => { loginSubmit(e); }} autoComplete="none">
            <h4>User login</h4>
            <span className="text-danger mb-2">{errorList && errorList.admin_input === undefined && errorList}</span>
            <div className="form-field d-flex align-items-center">

              <input className="form-control" type="text" name="userName" id="userName" placeholder="Email"
                value={username}
                onChange={(e) => setUserName(e.target.value)} autoComplete="none" />
            </div>
            {errorList && errorList.admin_input !== undefined && (<><span className='text-danger'>{errorList.admin_input[0]}</span><br /><br /></>)}
            <div className="form-field d-flex align-items-center">

              <input className="form-control mt-2" type="password" name="password" id="pwd" placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="none" required />
            </div>
            {errorList && errorList.password !== undefined && (<><span className='text-danger'>{errorList.password[0]}</span><br /></>)}
            <button className="btn mt-4 loginSubmitBtn">Login</button>
          </form>
        </div>

        {/* <div className="text-center fs-6">
            <a href="#">Forget password?</a> or <a href="#">Sign up</a>
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(Login);
