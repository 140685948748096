import { configureStore } from "@reduxjs/toolkit";
import UserData from "./UserData";
import LoadCheckData from "./LoadCheckData";

export default configureStore({
  reducer: {
    UserData: UserData,
    LoadCheckData: LoadCheckData,
  }
});
