import html2pdf from 'html2pdf.js';
export default function GeneratePDF(printID) {
  const element = document.getElementById(printID); // Replace 'divToPrint' with the ID of your div
  // Get the width of the div you want to print
  const divWidth = element.offsetWidth;

  // Calculate the height based on the content's actual height
  const divHeight = element.offsetHeight;

  // Calculate the aspect ratio to maintain proportions
  const aspectRatio = divHeight / divWidth;
  const opt = {
    margin: 0.5,
    filename: 'invoice.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    //   jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    //   jsPDF: { unit: 'px', format: [divWidth, 240] },
    jsPDF: { unit: 'px', format: [divWidth, divWidth * aspectRatio] },
    pagebreak: { mode: 'avoid-all' } // Avoid page breaks
  };

  // html2pdf().from(element).set(opt).save();
  const pdfInstance = html2pdf().from(element).set(opt);

  // Get the pdf data as a data URI
  //   pdfInstance.output('datauri').then((dataUri) => {
  //     // Create an iframe and set its source to the PDF data URI
  //     const iframe = document.createElement('iframe');
  //     iframe.src = dataUri;
  //     iframe.style.display = 'none';
  //     document.body.appendChild(iframe);

  //     // Wait for the iframe to load, then trigger the print dialog
  //     iframe.onload = function () {
  //       iframe.contentWindow.print();
  //       document.body.removeChild(iframe); // Remove the iframe after printing
  //     };
  //   });
  pdfInstance.output('datauri').then((dataUri) => {
    // Open a new window with the PDF data URI
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write('<embed width="100%" height="100%" type="application/pdf" src="' + dataUri + '"></embed></body></html>');
    // printWindow.document.write('</body></html>');
    // printWindow.print();
  });
}

// export default function GeneratePDF(printID) {
//   const element = document.getElementById(printID);
//   const divWidth = element.offsetWidth;
//   const divHeight = element.offsetHeight;
//   const aspectRatio = divHeight / divWidth;
  
//   // Set the width to fit the POS printer paper width (e.g., 60mm)
//   const printerPaperWidth = 60; // Width in millimeters
//   const pdfWidth = printerPaperWidth;
  
//   // Calculate the PDF height based on the aspect ratio
//   const pdfHeight = pdfWidth * aspectRatio;

//   const opt = {
//     margin: [0, 0, 2, 0], // Set margins to 10mm on all sides
//     filename: 'invoice.pdf',
//     image: { type: 'jpeg', quality: 0.98 },
//     html2canvas: { scale: 2 },
//     jsPDF: { unit: 'mm', format: [pdfWidth, pdfHeight] }, // Use millimeters as unit
//     pagebreak: { mode: 'avoid' } // Avoid unnecessary breaks
//   };

//   const pdfInstance = html2pdf().from(element).set(opt);
//   pdfInstance.output('datauri').then((dataUri) => {
//     const printWindow = window.open('', '_blank');
//     printWindow.document.write('<html><head><title>Print</title></head><body>');
//     printWindow.document.write('<embed width="100%" height="100%" type="application/pdf" src="' + dataUri + '"></embed></body></html>');
//   });
// }


// normal print
// var printContents = document.getElementById(printID).innerHTML;
//     var originalContents = document.body.innerHTML;

//     document.body.innerHTML = printContents;

//     window.print();

//     document.body.innerHTML = originalContents;