import React, { useState } from "react";
// import Footer from './Footer';
import Header from "./Sidebar";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "../assets/css/layout/sidebar.css";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  return (
    <Container fluid className="pos_container">
      <ToastContainer />
      {/* <Row className='justify-content-center'>
                <Col lg={12} className='d-flex p-0'>
     
                    <Header />
                    <div className="d-flex flex-column body_container">
                        {children}
                    </div>
                </Col>
            </Row> */}
      <div id="body-pd" className={`${show ? "body-pd" : ""}`}>
        <header className={`header ${show ? "body-pd" : ""}`} id="header">
          <div className="d-flex justify-content-between">
            <div
              className="header_toggle"
              onClick={() => {
                setShow(!show);
              }}
            >
              {" "}
              <i className="bx bx-menu" id="header-toggle"></i>{" "}
            </div>
            {/* <div className="header_img">
              {" "}
              <img src="https://i.imgur.com/hczKIze.jpg" alt="" />{" "}
            </div> */}
          </div>



        </header>
        <Sidebar show={show} />
        {/* <!--Container Main start--> */}
        <div className="height-100  main_pos_content">
          {children}
        </div>
        {/* <!--Container Main end--> */}
      </div>
    </Container>
  );
};
export default React.memo(Layout);
