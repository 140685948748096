import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useCart } from "react-use-cart";
import Toaster from "./common/Toaster";

const ProductAttributes = (props) => {

    const { addItem } = useCart();
    const [selectedAttributeIndex, setSelectedAttributeIndex] = useState(0);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (props?.modalProductAttributes?.attributes) {
                if (event.key === "ArrowRight" && selectedAttributeIndex < props.modalProductAttributes.attributes.length - 1) {
                    setSelectedAttributeIndex(selectedAttributeIndex + 1);
                } else if (event.key === "ArrowLeft" && selectedAttributeIndex > 0) {
                    setSelectedAttributeIndex(selectedAttributeIndex - 1);
                } else if (event.key === "Enter") {
                    const selectedAttribute = props.modalProductAttributes.attributes[selectedAttributeIndex];
                    if (selectedAttribute && selectedAttribute.stock > 0) {
                        addItem({
                            id: props.modalProductAttributes.id + '-' + selectedAttribute.attribute_id,
                            product_id: props.modalProductAttributes.id,
                            name: props.modalProductAttributes.name + " - " + selectedAttribute.attribute_value,
                            price: Number(selectedAttribute.final_product_price).toFixed(2),
                            quantity: 1,
                            image: props.modalProductAttributes.image.small,
                            stock: selectedAttribute.stock,
                            variation_id: selectedAttribute.attribute_id,
                            variation_final_price: selectedAttribute.final_product_price,
                            variation_stock: selectedAttribute.stock,
                            variation_value: selectedAttribute.attribute_value,
                        });
                        Toaster('Product added to cart', 'success');
                        props.onHide(); // Close the modal after adding the item
                    } else {
                        Toaster('Product is out of stock','info');
                    }
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedAttributeIndex, props]);
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.modalProductAttributes?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        {props?.modalProductAttributes?.attributes && props?.modalProductAttributes?.attributes.map((attribute, index) => {
                            return (
                                <div key={index} className={`col-md-4 mb-4`}>
                                    <div className={`product_attribute_cart ${selectedAttributeIndex === index ? 'selected' : ''}`} onClick={() => {
                                        if (attribute.stock > 0) {
                                            addItem({
                                                id: props?.modalProductAttributes?.id + '-' + attribute.attribute_id,
                                                product_id: props?.modalProductAttributes?.id,
                                                name: props?.modalProductAttributes?.name + " - " + attribute.attribute_value,
                                                price: Number(attribute.final_product_price).toFixed(2),
                                                quantity: 1,
                                                image: props?.modalProductAttributes?.image.small,
                                                stock: attribute.stock,
                                                variation_id: attribute.attribute_id,
                                                variation_final_price: attribute.final_product_price,
                                                variation_stock: attribute.stock,
                                                variation_value: attribute.attribute_value,
                                            });
                                            Toaster('Product added to cart', 'success');
                                        } else {
                                            Toaster('Product is out of stock','info');
                                        }
                                    }}>
                                        {attribute && isNaN(Number(attribute.discount)) && (<span className="discount_badge">{attribute.discount}</span>)}
                                        <div className="">
                                            <p className="my-2"><b>{attribute.attribute_value}</b></p>
                                            {attribute.color && <p className="mb-0">Color: <b>{attribute.color}</b></p>}
                                        </div>
                                        <div className="">
                                            <p className="my-2">{attribute.formatted_final_product_price} {isNaN(Number(attribute.discount)) && (<span className="old-price">{attribute.formatted_regular_price}</span>)}</p>
                                            {attribute.stock > 0 ?
                                                <span className="in_stock">In Stock</span> :
                                                <span className="out_stock">Out Of Stock</span>}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-bottom-0 border-top-0 my-2">
                    {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProductAttributes;
