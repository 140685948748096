import { createSlice } from "@reduxjs/toolkit";

export const LoadCheckDataSlice = createSlice({
  name: "LoadCheckData",
  initialState: {
    LoadCheckData: 0,
  },
  reducers: {
    setLoadCheckData: (state, action) => {
      state.LoadCheckData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLoadCheckData } = LoadCheckDataSlice.actions;

export default LoadCheckDataSlice.reducer;

