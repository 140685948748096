import React, { useRef } from "react";
import '../assets/css/layout/Header.css'
import { Link, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../store/UserData";
import sitelogo from "../assets/images/icons/KANFAST_White.svg";
import siteIcon from "../assets/images/icons/onlyLetterWhite.png";

const Sidebar = ({ show }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { UserData } = useSelector((state) => state.UserData);
  const logOut = () => {
    ref.current.continuousStart();
    if (UserData || localStorage.getItem("posUser")) {
      axios.post("pos/logout")
        .then(function (resp) {
          // console.log(resp.data);
          if (resp.data.success) {
            ref.current.complete();
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: 'Successfully logged out',
              showConfirmButton: false,
              timer: 1500
            });
            navigate("/login", { replace: true });

          }
          // console.log(defaultAddress);
        })
        .catch((err) => {
          ref.current.complete();
          console.log(err);
        });
      localStorage.removeItem("posUser");
      dispatch(setUserData(null));
    } else {
      navigate("/", { replace: true });
    }
  };
  return (
    <>
      <LoadingBar
        color='#003342'
        ref={ref}
      />

      <div className={`l-navbar ${show ? "show" : ""}`} id="nav-bar">
        <nav className="nav">
          <div>
            {" "}
            <Link to="/" className="nav_logo">
              {" "}
              {/* <i className="bx bx-layer nav_logo-icon"></i> */}
              
              <div className={`sidebar_logo ${show ? "d-none" : "d-block" }`}>
                <img src={UserData?.store?.store_image ?? siteIcon} className=" sidebar_logoimg" />
              </div>
              {" "}
              <span className="nav_logo-name d-flex align-items-center">
                <img src={UserData?.store?.store_image ?? sitelogo} className="img-fluid" style={{ maxWidth: '8rem', maxHeight: '3rem' }} />
                &nbsp;{UserData?.name}
                </span>{" "}
            </Link>
            <div className="nav_list">
              {" "}
              <Link to={'/'} className={`nav_link ${window.location.pathname === '/' ? 'active' : ''}`}>
                {" "}
                <i className="bx bx-store nav_icon"></i>{" "}
                <span className="nav_name">Dashboard</span>{" "}
              </Link>{" "}
              <Link to={'/orders'} className={`nav_link ${window.location.pathname === '/orders' ? 'active' : ''}`}>
                {" "}
                <i className="bx bxs-package nav_icon"></i>{" "}
                <span className="nav_name">Orders</span>{" "}
              </Link>{" "}
              <Link to={'/my-orders'} className={`nav_link ${window.location.pathname === '/my-orders' ? 'active' : ''}`}>
                {" "}
                <i className="bx bxs-shopping-bags nav_icon"></i>{" "}
                <span className="nav_name">My Orders</span>{" "}
              </Link>{" "}
            </div>
          </div>{" "}
          <a href="#" className="nav_link" onClick={(e) => {
            e.preventDefault()
            logOut();
          }}>
            {" "}
            <i className="bx bx-log-out nav_icon"></i>{" "}
            <span className="nav_name">SignOut</span>{" "}
          </a>
        </nav>
      </div>

    </>
  );
};

export default React.memo(Sidebar);
