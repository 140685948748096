import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import SelectSearch from "react-select-search";
import Swal from "sweetalert2";

const AddCustomerModal = (props) => {
    const navigate = useNavigate();
    const [addCustomerDisable, setAddCustomerDisable] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [address, setAddress] = useState("");
    // const [city, setCity] = useState("");
    const [errorList, setError] = useState();
    const addCustomer = (event) => {
        setAddCustomerDisable(true);
        var obj = {
          customer_name: username,
          customer_email: email,
          customer_contact: contact,
          address: address
        };
        // console.log(props.customer_id);
        // console.log(obj);
        axios
          .post("pos/register-customer", obj)
          .then(function (resp) {
            setAddCustomerDisable(false);
            // console.log(resp.data);
            var data = resp.data;
            console.log(data);
            if (data.success === false) {
    
              setError(data.message);
            }
            else if (data.message) {
              Swal.fire({
                customClass: {
                  icon: 'mt-4'
                },
                position: 'center',
                icon: 'success',
                title: data.message,
                showConfirmButton: true,
              });
              // navigate("/customer/address");
              props.setCustomer(data.data.customer[0]);
              // setSelectedCustomerAddress([data.data.customer[1]]);
              setError(null);
              props.setAddCustomerModal(false);
            }
            if (resp?.data?.message === "Unauthorized") {
              localStorage.removeItem("posUser");
              navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
            }
          })
          .catch((err) => {
            setAddCustomerDisable(false);
            console.log(err);
            setError(err?.response?.data?.errors)
          });
    
        // console.log('errorlist');
        // console.log(errorList);
        event.preventDefault();
      };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Customer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ps-4 pe-4">
                        <form onSubmit={(e) => { addCustomer(e); }}>
                            <div className="form-group mb-2">
                                <label htmlFor="name" className="required">
                                    Full Name:
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter your full name"
                                    className="form-control square"
                                />
                                {errorList && (<span className='text-danger'>{errorList.customer_name && errorList.customer_name[0]}</span>)}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="email">Email:</label>
                                <input id="email" type="email" name="email" value={email}
                                    onChange={(e) => setEmail(e.target.value)} placeholder="your-email@domain.com" className="form-control square" />
                                {errorList && (<span className='text-danger'>{errorList.customer_email && errorList.customer_email[0]}</span>)}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="phone" className="required">
                                    Phone:
                                </label>
                                <input id="phone" type="text" name="phone" placeholder="Enter Customer Phone Number" value={contact} onChange={(e) => setContact(e.target.value)} className="form-control square" />
                                {errorList && (<span className='text-danger'>{errorList.customer_contact && errorList.customer_contact[0]}</span>)}
                            </div>
                         
                            <div className="form-group mb-2">
                                <label htmlFor="address" className="required">
                                    Address:
                                </label>
                                <input
                                    id="address"
                                    type="text"
                                    name="address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    // required="required"
                                    placeholder="Enter your address"
                                    className="form-control square"
                                />
                                {errorList && (<span className='text-danger'>{errorList.address && errorList.address[0]}</span>)}
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn customer_add_btn" disabled={addCustomerDisable}>
                                    Register
                                </button>&nbsp;
                                <button type="reset" className="btn btn-light" onClick={props.onHide}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddCustomerModal;
