import React from "react";
import "../assets/css/invoice.css";
import sitelogo from "../assets/images/icons/KANFAST_White.svg";

const PosInvoice = ({ products, grandTotal, tax, storeDetails, orderDetails }) => {

  return (
    <>
      <div
        className="print-invoice-sec "
        style={{ visibility: "hidden", position: "fixed" }}
      >
        <div id="invoice-POS" className="" style={{ width: "15rem"}}>
          <center id="top">
            {/* <img src={storeDetails?.store?.store_image} className="img-fluid" width={40} height={40} /> */}
            {/* {storeDetails?.store?.store_image && <img src={storeDetails?.store?.store_image} className="img-fluid" width={40} height={40} />} */}
            <div className="">
              <h2>{storeDetails?.store?.store_name}</h2>
            </div>
          </center>

          <div id="mid">
            <div className="info">
              <center>
                {/* <h2>Contact Info</h2> */}
                <p style={{ lineHeight: '1.5' }}>
                  {storeDetails?.store?.store_address}
                  <br />
                  <b>Sales Person</b>: {storeDetails?.name}
                  <br />
                  <b>Invoice No</b>: {orderDetails?.order_number}
                  <br />
                  {orderDetails?.date}
                  <br />
                  {orderDetails?.time}
                  <br />
                </p>
              </center>
            </div>
          </div>

          <div id="bot">
            <div id="table">
              <table>
                <tr className="tabletitle">
                  <td className="item p-1">
                    <h2 className="mb-0">Item</h2>
                  </td>
                  <td className="Hours p-1">
                    <h2 className="mb-0">Qty</h2>
                  </td>
                  <td className="Rate p-1 text-center">
                    <h2 className="mb-0">Price</h2>
                  </td>
                </tr>

                {products.map((item, key) => {
                  return (
                    <tr key={key} className="service">
                      <td className="tableitem ps-1" style={{ width: '50%' }}>
                        <p className="itemtext">{item.name}</p>
                      </td>
                      <td className="tableitem ps-2">
                        <p className="itemtext">{item.quantity}</p>
                      </td>
                      <td className="tableitem ps-3 text-center">
                        <p className="itemtext pe-3">৳{item.price}</p>
                      </td>
                    </tr>
                  );
                })}

                <tr className="tabletitle">
                  {/* <td></td> */}
                  <td className="Rate px-1">
                    <h2 className="">Discount</h2>
                  </td>
                  <td></td>
                  <td className="payment">
                    <h2 className="">৳{orderDetails?.discount}</h2>
                  </td>
                </tr>
                <tr className="tabletitle">
                  {/* <td></td> */}
                  <td className="Rate px-1">
                    <h2 className="">Tax</h2>
                  </td>
                  <td></td>
                  <td className="payment">
                    <h2>৳{tax > 0 ? Number(tax.toFixed(2)) : '0.00'}</h2>
                  </td>
                </tr>

                <tr className="tabletitle">
                  {/* <td></td> */}
                  <td className="Rate px-1">
                    <h2>Total</h2>
                  </td>
                  <td></td>
                  <td className="payment">
                    <h2>৳{orderDetails?.pay_amount}</h2>
                  </td>
                </tr>
              </table>
            </div>

            <div id="legalcopy">
              <center>
                <p className="legal">
                  <strong>Thank you for your business!</strong> <br />
                  <small>© 2024 KANFAST</small>
                </p>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PosInvoice;