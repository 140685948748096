import axios from "axios";
import React, { useEffect, useState } from "react";
import all_cate from '../assets/images/home/all_cate.png';

const Categories = (props) => {
    const [categories, setCategories] = useState();
    useEffect(() => {
        if (!categories) {
            axios.get("get-categories")
              .then(resp => {
                setCategories(resp.data.categories);
              });
          }

    }, []);

    return (
        <>
         <h3 className="px-2 pt-2">Categories</h3>
              <div className="category_div">
                <button className={props.cateID ? "category_card" : "category_card active"} onClick={() => {
                  props.setCateID(null);
                }}>
                  <span role="img" aria-label="database" className="catIcon">
                    <img className="catIcon" src={all_cate} alt='All'
                      width="40" height="40" />
                  </span>
                  <p className="m-0">All</p>
                  
                </button>
                {categories &&
                  (
                    categories.map((category, index) => {
                      return (
                        <button key={index} className={props.cateID === category.id ? "category_card active" : "category_card"} onClick={() => {
                            props.setCateID(category.id);
                        }}>
                          <span role="img" aria-label="database" className="catIcon">
                            <img className="catIcon" src={category.category_image} alt={category.category_name}
                              width="40" height="40" />
                          </span>
                          <p className="m-0" title={category.category_name}>{category.category_name}</p>
                        </button>
                      );
                    })
                  )
                }
              </div>
        </>
    );
};

export default Categories;
