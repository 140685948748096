import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useCart } from "react-use-cart";
import search_icon from '../assets/images/icons/Search.svg';
import bar_icon from '../assets/images/icons/bar.png';
import Swal from 'sweetalert2';
import Toaster from "../components/common/Toaster"
import ProductAttributes from "./ProductAttributes";

const AllProductsModal = (props) => {
    const navigate = useNavigate();
    const [searchProduct, setSearchProduct] = useState();
    const [products, setProducts] = useState();
    const { UserData } = useSelector((state) => state.UserData);
    const [query, setQuery] = useState("?platform=pos");
    const search = useLocation().search;
    const queryParam = new URLSearchParams(search);
    const [isEventTriggured, setIsEventTriggured] = useState(false);
    const searchInputRef = useRef(null);
    const [productAttributesOpen, setProductAttributesOpen] = useState();
    const [modalProductAttributes, setModalProductAttributes] = useState();

    useEffect(() => {
        setQuery("");
        if (searchProduct) {
            setQuery("?platform=pos&product_name=" + searchProduct);
        }
        else if (searchProduct) {
            setQuery("?platform=pos&product_name=" + searchProduct);
        }
        else {
            setQuery("?platform=pos");
        }
        // console.log(query);
    }, [searchProduct]);


    useEffect(() => {
        // setProducts(null);
        // console.log(searchProduct);
        if (UserData?.token) {
            if (searchProduct && query) {
                axios.get("pos/products" + query)
                    .then(resp => {
                        if (resp.data.success === false && queryParam.get('token') === null) {
                            if (resp.data.message == "Unauthorized") {
                                localStorage.removeItem("posUser");
                                navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
                            }
                            setProducts(null);
                        }
                        else {
                            setProducts(resp.data.data);
                            // console.log(resp.data.data);
                        }
                    });
            }
            else if (searchProduct && query) {

                axios.get("pos/products" + query)
                    .then(resp => {
                        setProducts(null);
                        if (resp.data.success === false && queryParam.get('token') === null) {
                            if (resp.data.message == "Unauthorized") {
                                localStorage.removeItem("posUser");
                                navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
                            }
                            setProducts(null);
                        }
                        else {
                            setProducts(resp.data.data);
                        }
                    });
            }
            else {
                axios.get("pos/products?platform=pos")
                    .then(resp => {
                        console.log(resp.data);
                        if (resp.data.success === false && queryParam.get('token') === null) {
                            if (resp.data.message == "Unauthorized") {
                                localStorage.removeItem("posUser");
                                navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
                            }
                            setProducts(null);
                        }
                        else {
                            setProducts(resp.data.data);
                        }
                    });
            }
        }
    }, [query, UserData]);

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEventTriggured(true)
            console.log("Enter key");
        }
    };

    const addOrUpdateItem = (product) => {
        const index = props?.exchangedProducts.findIndex(item => item.id === product.id);
        if (index !== -1) {
            const newProducts = [...props?.exchangedProducts];
            newProducts[index].qty += 1; // Update quantity
            props?.setExchangedProducts(newProducts);
        } else {
            props?.setExchangedProducts([...props?.exchangedProducts, {
                id: product.id,
                product_id: product.id,
                variation_id: product.variation_id,
                product_name: product.product_name,
                product_price: product.product_price,
                qty: 1,
                stock: product.stock,
                image: product.image,
                new_product: true,
                exchange_order_details_id: product?.exchange_order_details_id
            }]);
        }
        props?.removeOrderDetail(product?.exchange_order_details_id);
    };
    return (
        <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Choose Products
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="products_tab">
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <div className="input-group ms-2 position-relative">
                                    <input type="search" className="form-control search_input rounded-1" placeholder="Search Product.." aria-label="Recipient's username" aria-describedby="basic-addon2"
                                        value={searchProduct}
                                        onChange={(e) => setSearchProduct(e.target.value)}
                                        onKeyDown={handleSearchKeyDown}
                                        ref={searchInputRef}
                                    />
                                    <span className="input-group-text search_input_icon" id="basic-addon2"><img src={search_icon} alt="search icon" /></span>
                                </div>
                            </Col>
                            <Col xs={3} md={1}>
                                <button className="btn basic_btn" onClick={() => {
                                    Swal.fire({
                                        title: 'Enter/Scan Bar Code',
                                        input: 'text',
                                        inputPlaceholder: 'Enter/Scan Bar Code',
                                        confirmButtonColor: '#09b3d4',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            setSearchProduct(result.value);
                                        }
                                    })
                                }}><img src={bar_icon} height={30} alt="bar icon" /></button>
                            </Col>
                            <Col xs={6} md={3}>
                                <p className="text-end pe-3">{products ? products.length : '0'} Products</p>
                            </Col>
                        </Row>



                        <div className="Default_productList">

                            <div className="product_list pb-5 d-flex gap-2 flex-wrap">
                                {products &&
                                    products.map((product, index) => {
                                        return (
                                            <div className="productmainDIv" key={index}>
                                            <div className="pos_product_card" onClick={() => {
                                                // errorNotify();
                                                // console.log(product.attributes?.length);
                                                if (product.attributes?.length < 2) {
                                                    if (product.stock > 0) {
                                                        addOrUpdateItem({
                                                            id: product.id + '_' + product.attribute_id,
                                                            product_id: product.id,
                                                            variation_id: product.attribute_id,
                                                            product_name: product.name,
                                                            product_price: Number(product.final_product_price).toFixed(2),
                                                            qty: 1,
                                                            stock: product.stock,
                                                            image: product.image.small,
                                                            new_product: true,
                                                            exchange_order_details_id: props?.exchangeDetailsId
                                                        });

                                                        Toaster('Product added to cart', 'success');
                                                    } else {
                                                        Toaster('Product is out of stock', 'error');
                                                    }
                                                }
                                                else {
                                                    if (productAttributesOpen == product.id) {
                                                        setProductAttributesOpen(null);
                                                    }
                                                    else {
                                                        setProductAttributesOpen(product.id);
                                                    }

                                                    setModalProductAttributes(product);
                                                }

                                            }}>
                                                {product && isNaN(Number(product.discount)) && (<span className="discount_badge">{product.discount}</span>)}
                                                <div className="position-relative">
                                                    <div xs={6} className="cardImage" >
                                                        {product && product.image && product.image && (

                                                            <img className="pos_product_img" src={product.image.small} alt={product.name} />
                                                        )}
                                                    </div>
                                                    <div xs={6} className="pos_product_details text-center">
                                                        <h2 className="mb-0" title={product.name}>{product.name}</h2>
                                                    </div>

                                                    <div className="p_price">
                                                        <p className="mb-0 d-flex align-items-center justify-content-center">{product.formatted_final_product_price} {isNaN(Number(product.discount)) && (<span className="old-price">{product.formatted_regular_price}</span>)}</p>
                                                    </div>
                                                    <div className="stock">
                                                        {product.stock > 0 ?
                                                            <span className="in_stock">In Stock</span> :
                                                            <span className="out_stock">Out Of Stock</span>}
                                                    </div>
                                                </div>
                                                <div className={`arrow-p-up ${productAttributesOpen == product.id ? '' : 'd-none'}`}>
                                                    <i class="fa fa-sort-asc up" aria-hidden="true"></i>
                                                </div>
                                            </div>

                                            <div className={`multipleAtt border mt-3 ${productAttributesOpen == product.id ? '' : 'd-none'}`} id="multipleAtt">
                                                <div className="row justify-content-center mt-4 multipleAttExp">
                                                    {product?.attributes.map((attribute, index) => {
                                                        return (
                                                            <div key={index} className={`col-md-4 mb-4`} onClick={() => {
                                                                if (attribute.stock > 0) {
                                                                    console.log('onclick working');
                                                                    addOrUpdateItem({
                                                                        id: product.id + '_' + attribute.attribute_id,
                                                                        product_id: product.id,
                                                                        variation_id: product.attribute_id,
                                                                        product_name: `${product.name} (${attribute.attribute_value})`,
                                                                        product_price: Number(attribute.final_product_price).toFixed(2),
                                                                        qty: 1,
                                                                        stock: product.stock,
                                                                        image: product.image.small,
                                                                        new_product: true,
                                                                        exchange_order_details_id: props?.exchangeDetailsId
                                                                    });
                                                                    Toaster('Product added to cart', 'success');
                                                                } else {
                                                                    Toaster('Product is out of stock', 'info');
                                                                }
                                                            }}>
                                                                <div className='product_attribute_cart'>
                                                                    {attribute && isNaN(Number(attribute.discount)) && (<span className="discount_badge">{attribute.discount}</span>)}
                                                                    <div className="">
                                                                        <p className="my-2"><b>{attribute.attribute_value}</b></p>
                                                                        {attribute.color && <p className="mb-0">Color: <b>{attribute.color}</b></p>}
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="my-2">{attribute.formatted_final_product_price} {isNaN(Number(attribute.discount)) && (<span className="old-price">{attribute.formatted_regular_price}</span>)}</p>
                                                                        {attribute.stock > 0 ?
                                                                            <span className="in_stock">In Stock</span> :
                                                                            <span className="out_stock">Out Of Stock</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        );
                                    })}
                            </div>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>
            {/* <ProductAttributes show={productAttributesOpen} modalProductAttributes={modalProductAttributes} onHide={() => setProductAttributesOpen(false)} /> */}
        </>
    );
};

export default AllProductsModal;
